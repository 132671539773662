import React from "react";
import { Link } from "gatsby";
import Helmet from 'react-helmet';
import Layout from "../components/layout";

export default () => {

  return (
    <Layout>
    <div style={{ 'position': 'relative' }}>
      <Link to='/oer-thema-und-12-variationen-ueber-happy-birthday-to-you' className="closeX"><span>X</span></Link>
      <h2>Beispielaufnahme zu den <i>Happy-Birthday-Variationen</i></h2>
    </div>
    <hr style={{ "margin": "40px 0" }} />
    <div>
      <Link to="/oer-thema-und-12-variationen-ueber-happy-birthday-to-you"><img src="/images/kaiser_openbook-happyfortwo.jpg" alt="" /></Link>
    </div>
    <p>
      Ulrich Kaiser, <span class="i">Thema und 12 Variationen über Happy Birthday To You</span> (=&nbsp;OpenBook&nbsp;11), für zwei beliebige Melodieinstrumente, Karlsfeld 2017.<br /><br />
      <a href="http://www.scribus.net/canvas/Scribus" target="_blank"><img src="/Images/scribusLogo.png" /></a>&nbsp;<img src="/Images/CC_BY-SA.png" /><br />
      Dieses OpenBook wurde mit der Opensource-Software <a href="http://www.scribus.net/canvas/Scribus" target="_blank">Scribus</a> unter
      <a href="https://creativecommons.org/licenses/by/3.0/de/">Creative Commons BY-SA</a> veröffentlicht.        
    </p>
    <hr style={{ "margin": "40px 0" }} />
    <div>
      <div>
        <audio className="mixingAudio" controls>
          <source src="/data/audio/happyfortwo/thema-und-12-variationen.mp3" type="audio/mpeg" />
          <p>Ihr Browser ist veraltete und unterstützt nicht das HTML5 Audio-Element. Das tut mir leid....</p>
        </audio>
        <p>
          Die Happy-Birthday-Variationen (originalen Reihenfolge)<br />
         Flöte: Veronika Minsinger, Klarinette: Anna Harzenetter, Arrangement/Aufnahme & Schnitt: Ulrich Kaiser 
        </p>
      </div>
      <hr style={{ "margin": "40px 0" }} />      
      <a href="/data/openbooks/happyfortwo/releases/happyfortwo_cd-cover.pdf">Druckvorlage für CD-Inlays</a>
    </div>
    <Helmet>
      <title>OER | Ulrich Kaiser - Happy-Birthday-Variationen (Aufnahmen)</title>
      <meta name="description" content="Hier finden Sie Beispielaufnahmen zu den Happy-Birthday-Variationen für zwei beliebige Melodieinstrumente von Ulrich Kaiser." />
      <meta name="keywords" content="Happy Birthday, Variationen, Melodiestimme, OER, Open Educational Resources, OpenBooks" />
      <meta name="copyright" content="Ulrich Kaiser, 2017" />
    </Helmet>
  </Layout>
  )
}

